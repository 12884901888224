<template>
    <div class='page'>
        <div class="body1">
            <img class="product1" src="@/assets/images/about1.png" alt="">
            <div class="info txt_center wow fadeInDown">
                <div class="title">{{ $t('about.value4') }}</div>
                <div class="desc">{{ $t('about.value5') }}</div>
            </div>
        </div>
        <div class="main dis_flex">
            <div class="main-left">
                <div class="navs">
                    <div class="nav" :class="[$i18n.locale == 'zh' ? 'zh' : 'en', activeBtn == index ? 'active' : '']"
                        v-for="(item, index) in navs" :key="index" @click="goAnchor(`body${index + 2}`, index)">
                        <img src="@/assets/images/about3.png" alt="" v-show="activeBtn == index">
                        {{ item }}
                    </div>
                </div>
            </div>
            <div class="main-right">
                <div class="body body2">
                    <div class="gap" ref="body2"></div>
                    <div class="title dis_flex items_center">
                        <img src="@/assets/images/index2.png" alt="">
                        {{ $t('about.value6') }}
                    </div>
                    <img class="about2" src="@/assets/images/about2.png" alt="">

                    <div class="info dis_flex">
                        <img class="index5" src="@/assets/images/index5.png" alt="">
                        <img class="index5-r" src="@/assets/images/index5.png" alt="">
                        <div class="info-l">
                            <img src="@/assets/images/about4.png" alt="">
                        </div>
                        <div class="info-r flex dis_flex jus_center flex_column">
                            <div class="info-title">{{ $t('about.value7') }}</div>
                            <div class="info-desc">{{ $t('about.value8') }}</div>
                        </div>
                    </div>
                </div>
                <div class="body body3">
                    <div class="gap" ref="body3"></div>
                    <img class="about5" src="@/assets/images/about5.png" alt="">
                    <div class="info">
                        <div class="title">
                            <img src="@/assets/images/index2.png" alt="">
                            {{ $t('about.value9') }}
                        </div>
                        <div class="subTitle">START</div>
                        <div class="history dis_flex">
                            <div class="history-l">
                                <div class="item dis_flex wow slideInLeft" :data-wow-delay="`${50 * index}ms`"
                                    v-for="(item, index) in history" :key="index">
                                    <div class="left dis_flex items_center flex_column">
                                        <img :src="(index == 0 || index == history.length - 1) ? require('@/assets/images/about7.png') : require('@/assets/images/about8.png')"
                                            alt="">
                                        <div class="line" v-if="index != history.length - 1"></div>
                                    </div>
                                    <div class="right dis_flex">
                                        <div class="time">{{ item.time }}</div>
                                        <div class="desc">{{ item.text }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="history-r dis_flex flex_column jus_between wow slideInRight">
                                <div class="imgs">
                                    <img src="@/assets/images/about9.png" alt="">
                                    <img src="@/assets/images/about10.png" alt="">
                                    <img src="@/assets/images/about11.png" alt="">
                                </div>
                                <div class="imgs">
                                    <img src="@/assets/images/about12.png" alt="">
                                    <img src="@/assets/images/about13.png" alt="">
                                    <img src="@/assets/images/about14.png" alt="">
                                </div>
                                <img class="about6" src="@/assets/images/about6.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="body body4" v-if="$i18n.locale == 'zh'">
                    <div class="gap" ref="body4"></div>
                    <div class="info">
                        <img class="about15" src="@/assets/images/about15.png" alt="">
                        <div class="info-main dis_flex items_center">
                            <img src="@/assets/images/about16.png" alt="">
                            <div class="info-text">
                                <div class="info-title">{{ $t('about.value22') }}</div>
                                <div class="info-desc">{{ $t('about.value23') }}</div>
                            </div>
                            <div class="flex_btn" @click="open">提交建议</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 改进建议弹窗 -->
        <Pop ref="pop">
            <div class="try">
                <div class="title txt_center">{{ questionnaireInfo.surveyName }}</div>
                <div class="desc txt_center" v-html="questionnaireInfo.surveyDescription"></div>
                <div class="form">
                    <div class="form-item" v-for="(item, index) in questionnaireProblem " :key="item.id">
                        <div class="label">{{ index + 1 + '、' + item.questionName }}</div>
                        <div class="value input" v-if="item.choices.type == 'text' && index != 3">
                            <input type="text" v-model="item.value">
                        </div>
                        <div class="value" v-if="item.choices.type == 'single'">
                            <el-radio-group v-model="item.value">
                                <el-radio v-for="(v, i) in item.choices.options" :key="i" :label="v.optionValue">{{ v.optionValue
                                }}</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="value input" v-if="index == 3">
                            <el-date-picker v-model="item.value" value-format="yyyy-MM-dd" type="date"
                                :clearable="false" placeholder="">
                            </el-date-picker>
                            <img src="@/assets/images/date.png" alt="">
                        </div>
                        <div class="value" v-if="item.choices.type == 'multi'">
                            <el-checkbox-group v-model="item.value">
                                <el-checkbox v-for="(v, i) in item.choices.options" :key="i" :label="v.optionValue"></el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <div @click="submit" class="flex_btn">立即提交</div>
            </div>
        </Pop>
    </div>
</template>

<script>
import { questionnaireDetail, questionnaireProblem, submitQuestionnaire } from '@/api/index'
import Pop from "@/components/pop.vue"
export default {
    components: { Pop },
    data() {
        return {
            questionnaireInfo: {},
            questionnaireProblem: [],
            disabled: false,
            activeBtn: null,
        }
    },
    computed: {
        navs() {
            if (this.$i18n.locale == 'en') return [this.$t('about.value1'), this.$t('about.value2')]
            else return [this.$t('about.value1'), this.$t('about.value2'), this.$t('about.value3')]
        },
        history() {
            return [
                { time: '2020.09', text: this.$t('about.value10') },
                { time: '2021.05', text: this.$t('about.value11') },
                { time: '2021.11', text: this.$t('about.value12') },
                { time: '2022.05', text: this.$t('about.value13') },
                { time: '2022.08', text: this.$t('about.value14') },
                { time: '2022.11', text: this.$t('about.value15') },
                { time: '2023.03', text: this.$t('about.value16') },
                { time: '2023.04', text: this.$t('about.value17') },
                { time: '2023.06', text: this.$t('about.value18') },
                { time: '2023.07', text: this.$t('about.value19') },
                { time: '2023.08', text: this.$t('about.value20') },
                { time: '2023.09', text: this.$t('about.value21') },
            ]
        }
    },
    created() {
        this.$nextTick(() => {   //页面渲染完，在执行
            new this.$wow({
                live: false
            }).init()
        })
        window.addEventListener('scroll', this.handleScroll)
        this.getQuestionnaireDetail()
        this.getQuestionnaireProblem()
    },
    methods: {
        async submit() {
            const requestData = {
                surveyId: this.questionnaireInfo.id,
                answers: [],
                tel: this.questionnaireProblem[1].value,
            }
            this.questionnaireProblem.forEach((item, index) => {
                requestData.answers.push({
                    questionName: item.questionName,
                    questionId: item.id,
                    answerType: item.choices.type,
                    value: item.choices.type == 'multi' ? item.value.join(',') : item.value,
                    answerType: 1,
                })
            })
            let res = await submitQuestionnaire(requestData)
            if(res.code == 0){
                this.$message({
                    message: '提交成功',
                    type: 'success'
                });
                this.$refs.pop.dialogVisible = false
                this.questionnaireProblem.forEach(item => {
                    if(item.choices.type == 'multi'){
                        this.$set(item, 'value', [])
                    }else{
                        this.$set(item, 'value', '')
                    }
                })
            }else{
                this.$message.warning(res.message)
            }
        },
        async getQuestionnaireDetail() {
            try {
                const res = await questionnaireDetail({ id: 148 })//TODO 暂时写死,没有后台配置
                this.questionnaireInfo = res.data
            } catch (err) {
                console.log(err);
            }
        },
        async getQuestionnaireProblem() {
            const res = await questionnaireProblem({ id: 148 })//TODO 暂时写死,没有后台配置
            this.questionnaireProblem = res.data
            this.questionnaireProblem.forEach((item, index) => {
                item.choices = JSON.parse(item.choices)
                if(item.choices.type == 'multi'){
                    this.$set(item, 'value', [])
                }else{
                    this.$set(item, 'value', '')
                }
                console.log(item.choices);
            })
        },
        open() {
            this.$refs.pop.dialogVisible = true
        },
        //锚点跳转
        goAnchor(selector, index) {
            if (this.disabled) return
            this.activeBtn = index
            this.disabled = true
            setTimeout(() => {
                this.disabled = false
            }, 800)
            this.$refs[selector].scrollIntoView({
                behavior: 'smooth'
            })
        },
        // 滚动监听器
        handleScroll(e) {
            // 获取所有锚点元素
            const navContents = document.querySelectorAll('.body')
            // 所有锚点元素的 offsetTop
            const offsetTopArr = []
            navContents.forEach((item) => {
                offsetTopArr.push(item.offsetTop)
            })
            // 获取当前文档流的 scrollTop
            const scrollTop = e.srcElement.scrollingElement.scrollTop + 130
            offsetTopArr.forEach((item, index) => {
                if (scrollTop >= item && !this.disabled) {
                    this.activeBtn = index
                }
            })
            if (scrollTop < offsetTopArr[0] && !this.disabled) this.activeBtn = null
        }
    },
}
</script>
<style scoped lang='scss'>
.body1 {
    position: relative;
    width: 100%;
    height: 800px;
    padding-top: 428px;
    box-sizing: border-box;

    .product1 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .info {
        color: #FFFFFF;
        position: relative;

        .title {
            font-size: 60px;
            font-weight: bold;
        }

        .desc {
            font-size: 22px;
        }
    }
}

.body {
    position: relative;

    .gap {
        position: absolute;
        width: 100%;
        height: 130px;
        top: -130px;
        left: 0;
        right: 0;
    }
}

.main {
    align-items: stretch;

    .main-left {
        width: 255px;
        position: relative;
        padding-top: 38px;

        .navs {
            position: sticky;
            top: 168px;
            left: 180px;
            width: fit-content;

            .nav {
                margin-bottom: 6px;
                cursor: pointer;
                box-sizing: border-box;
                padding: 34px 20px 0;
                font-weight: bold;
                background: #FFFFFF;
                box-shadow: 0px 6px 4px 0px rgba(204, 201, 201, 0.5);
                border-radius: 26px 0px 0px 0px;
                position: relative;
                text-align: center;

                img {
                    width: 32px;
                    height: 32px;
                    position: absolute;
                    top: 4px;
                    left: 18px;
                }

                &.zh {
                    width: 68px;
                    height: 166px;
                }

                &.en {
                    width: 150px;
                    height: 120px;
                    text-align: left;
                    line-height: 1.4;
                }

                &.active {
                    background: #0F228F;
                    color: #fff;
                }
            }
        }
    }

    .main-right {
        flex: 1;
        border-left: 1px solid #ABABAB;
    }
}

.body2 {
    padding: 70px 160px 90px 80px;

    position: relative;
    box-sizing: border-box;

    .title {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 75px;

        img {
            width: 72px;
            height: 72px;
            margin-right: 8px;
        }
    }

    .about2 {
        width: 573px;
        position: absolute;
        top: 0;
        right: 0;
    }

    .info {
        position: relative;

        .index5 {
            position: absolute;
            left: -40px;
            top: -40px;
            width: 80px;
            height: 80px;
        }

        .index5-r {
            position: absolute;
            right: -40px;
            bottom: -40px;
            width: 80px;
            height: 80px;
            transform: rotate(180deg);
        }

        .info-l {
            width: 380px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .info-r {
            background: linear-gradient(100deg, #FFFFFF 0%, #F2F1F1 100%);
            padding: 50px 80px 50px 150px;
            box-sizing: border-box;

            .info-title {
                color: #00239C;
                font-size: 37px;
                font-weight: bold;
                margin-bottom: 27px;
            }

            .info-desc {
                font-size: 20px;
            }
        }
    }
}

.body3 {
    position: relative;

    .about5 {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
    }

    .info {
        padding: 50px 160px 50px 67px;
        box-sizing: border-box;
        position: relative;

        .title {
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 50px;
            color: #fff;

            img {
                width: 72px;
                height: 72px;
                margin-right: 8px;
            }
        }

        .subTitle {
            color: #BAD4DD;
            font-size: 56px;
            font-weight: bold;
            margin: 50px 0 26px;
            line-height: 1.2;
        }

        .history {
            .history-l {
                flex: 1;
                margin-right: 30px;

                .item {
                    .left {
                        margin-right: 25px;

                        img {
                            width: 18px;
                            height: 18px;
                        }

                        .line {
                            min-height: 30px;
                            height: 100%;
                            width: 1px;
                            background: #BAD4DD;
                            margin: 20px 0;
                        }
                    }

                    .right {
                        flex: 1;
                        line-height: 1.2;
                        padding-bottom: 50px;

                        .time {
                            color: #AFCBFB;
                            font-size: 22px;
                            font-weight: bold;

                        }

                        .desc {
                            margin-left: 10%;
                            font-size: 24px;
                            color: #fff;
                            width: 100%;
                        }
                    }
                }
            }

            .history-r {
                margin-left: auto;
                width: 290px;
                position: relative;

                .imgs {
                    img {
                        width: 230px;
                        height: 140px;
                        margin-bottom: 22px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .about6 {
                    width: 30px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                }
            }

        }


    }
}

.body4 {
    padding: 73px 160px 73px 50px;
    box-sizing: border-box;

    .info {
        width: 100%;
        height: 220px;
        position: relative;

        .about15 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .info-main {
            position: relative;
            height: 100%;
            padding-left: 45px;
            padding-right: 70px;

            img {
                width: 100px;
                height: 100px;
            }

            .info-text {
                margin-left: 7px;

                .info-title {
                    font-size: 34px;
                    font-weight: bold;
                    margin-bottom: 16px;
                }

                .info-desc {
                    font-size: 16px;
                    max-width: 730px;
                }
            }

            .flex_btn {
                width: 194px;
                height: 70px;
                border-radius: 35px;
                background: #00239C;
                color: #fff;
                font-size: 20px;
                font-weight: bold;
                margin-left: auto;
                cursor: pointer;
            }
        }
    }
}

.try {
    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 5px;
        line-height: 1.2;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .desc {
        width: 80%;
        color: #5D5D5D;
        font-size: 12px;
        margin: 0 auto;
    }

    .form {
        height: 370px;
        overflow: auto;
        padding: 0 26px;
        box-sizing: border-box;

        .form-item {
            padding-top: 40px;

            &:last-child {
                padding-bottom: 40px;
            }

            .label {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.2;
            }

            .value {
                margin-top: 6px;
                position: relative;

                &.input {
                    height: 50px;
                    border-bottom: 1px solid #000000;
                }

                input {
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .el-radio-group {
                    display: flex;
                    flex-direction: column;

                    .el-radio {
                        margin-top: 5px;

                        ::v-deep .el-radio__inner {
                            width: 18px;
                            height: 18px;
                        }

                        ::v-deep .el-radio__label {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .flex_btn {
        width: 198px;
        height: 54px;
        background: #00239C;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin: 20px auto 0;
        cursor: pointer;
    }
}

::v-deep .el-date-editor.el-input {
    width: 100%;
}

::v-deep .el-input__inner {
    border: none;
    padding-left: 0 !important;
}

::v-deep .el-input__prefix {
    display: none;
}

input {
    border: 0;
    /*清除自带的2px的边框*/
    padding: 0;
    /*清除自带的padding间距*/
    outline: none;
    /*清除input点击之后的黑色边框*/
}

::-webkit-scrollbar {
    background-color: #ECECEC;
    border-radius: 4px;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #00239C;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #00239C;
}
</style>
